import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-scroll';
import { useStaticQuery, graphql, Link as MoveLink } from "gatsby";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
  logo: {
    width: "100%",
    textAlign: "center",
  },
  logoImg: {
    height: 83,
    width: 115,
    margin: "10px auto",
  },
  imgIcon: {
    height: 20,
    width: 20,
    borderRadius: 10,
  },
});

const text = {
  vi: {
    homepage: "Trang Chủ",
    aboutUs: "Giới Thiệu",
    service: "Dịch Vụ",
    completedProject: "Công trình đã thực hiện",
    contact: "Liên Hệ",
  },
  en: {
    homepage: "Home",
    aboutUs: "About Us",
    service: "Services",
    completedProject: "Projects",
    contact: "Contact",
  },
};

export default function MenuMobile({
  isOpen,
  language,
  currentPath,
  callback,
}) {
  const classes = useStyles();

  // data from graphql
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 83) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_vn: file(relativePath: { eq: "vn.png" }) {
        childImageSharp {
          fluid(maxHeight: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_en: file(relativePath: { eq: "us.png" }) {
        childImageSharp {
          fluid(maxHeight: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const toggleDrawer = (status) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    callback(status);
  };

  // const getExtLink = () => {
  //   return language === "vi" ? "" : "/en";
  // };

  // const getHomeLink = () => {
  //   return language === "vi" ? "/" : "/en";
  // };

  return (
    <div>
      <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
        {/** Logo */}
        <div className={classes.logo}>
          <Img
            className={classes.logoImg}
            fluid={data.logo.childImageSharp.fluid}
          />
        </div>

        <Divider />

        {/** Menu */}
        <div
          className={classes.list}
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <Link
              to='home'
              className={classes.link}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={toggleDrawer(false)}
            >
              <ListItem button>
                <ListItemText primary={text[language].homepage} />
              </ListItem>
            </Link>

            <Link
              to='service'
              className={classes.link}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={toggleDrawer(false)}
            >
              <ListItem button>
                <ListItemText primary={text[language].service} />
              </ListItem>
            </Link>

            <Link
              to='contact'
              className={classes.link}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={toggleDrawer(false)}
            >
              <ListItem button>
                <ListItemText primary={text[language].contact} />
              </ListItem>
            </Link>

            <Link
              to='project'
              className={classes.link}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={toggleDrawer(false)}
            >
              <ListItem button>
                <ListItemText primary={text[language].completedProject} />
              </ListItem>
            </Link>
          </List>

          <Divider />

          {/** Language */}
          <List>
            <MoveLink to={currentPath} className={classes.link}>
              <Button
                size='large'
                className={classes.btnLanguage}
                startIcon={
                  <Img
                    className={classes.imgIcon}
                    fluid={data.image_vn.childImageSharp.fluid}
                  />
                }
              >
                Việt Nam
              </Button>
            </MoveLink>

            <MoveLink to={`/en${currentPath}`} className={classes.link}>
              <Button
                size='large'
                className={classes.btnLanguage}
                startIcon={
                  <Img
                    className={classes.imgIcon}
                    fluid={data.image_en.childImageSharp.fluid}
                  />
                }
              >
                English
              </Button>
            </MoveLink>
          </List>
        </div>
      </Drawer>
    </div>
  );
}

MenuMobile.defaultProps = {
  isOpen: false,
  language: "vi",
  currentPath: "",
};

MenuMobile.propTypes = {
  isOpen: PropTypes.bool,
  language: PropTypes.string,
  currentPath: PropTypes.string,
};
