import React from "react";
import "./CallAction.css";

export default function CallAction() {
  return (
    <div
      className='phonering-alo-phone phonering-alo-green phonering-alo-show'
      id='phonering-alo-phoneIcon'
    >
      <div className='phonering-alo-ph-circle'></div>
      <div className='phonering-alo-ph-circle-fill'></div>
      <p className="call-text"> HOTLINE: 0937415072 </p>
      <a href='tel:0937415072' className='pps-btn-img' title='Liên hệ'>
        <div className='phonering-alo-ph-img-circle'></div>
      </a>
    </div>
  );
}
