import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: "none",
    width: 83,
    margin: '0 auto',
  },
  img: {
    width: 83
  },
}))

const Logo = () => {
  const classes = useStyles()

  // data from graphql
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 83) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Link to="/" className={classes.root}>
      <Img
        className={classes.img}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </Link>
  )
}

export default Logo
