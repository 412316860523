import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";

import Menu from "./Menu";
import MenuMobile from "./MenuMobile";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "white",
    color: "#2e4d68",
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 5,
    },
    width: '90%',
    margin: "0px auto",
    padding: "10px 0px",
  },
}));

export default function Header({ language, currentPath }) {
  const classes = useStyles();
  const [isOpenMobileMenu, setIsOpenMobileMenu] = React.useState(false);

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar variant='dense' className={classes.toolbar}>
          {/** Button Menu */}
          <Hidden smUp>
            <IconButton
              size='large'
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => setIsOpenMobileMenu(true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Hidden>

          {/** Logo */}
          <Logo />

          {/** Menu Desktop */}
          <Hidden smDown>
            <Menu language={language} currentPath={currentPath} />
          </Hidden>

          <Hidden smUp>
            <MenuMobile
              language={language}
              currentPath={currentPath}
              isOpen={isOpenMobileMenu}
              callback={(status) => setIsOpenMobileMenu(status)}
            />
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}
