import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link as MoveLink } from "gatsby";
import { Link } from 'react-scroll';
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "right",
  },
  item: {
    margin: "0 10px",
  },
  link: {
    textDecoration: "none",
  },
  imgIcon: {
    height: 20,
    width: 20,
    borderRadius: 10
  }
}));

const text = {
  vi: {
    'homepage': "Trang Chủ",
    'aboutUs': "Giới Thiệu",
    'service': "Dịch Vụ",
    'completedProject': "Công trình đã thực hiện",
    'contact': "Liên Hệ"
  },
  en: {
    'homepage': "Home",
    'aboutUs': "About Us",
    'service': "Services",
    'completedProject': "Projects",
    'contact': "Contact"
  }
}

export default function Menu({ language, currentPath }) {
  const classes = useStyles();

  // data from graphql
  const data = useStaticQuery(graphql`
    query {
      image_vn: file(relativePath: { eq: "vn.png" }) {
        childImageSharp {
          fluid(maxHeight: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_en: file(relativePath: { eq: "us.png" }) {
        childImageSharp {
          fluid(maxHeight: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <Link to="home" className={classes.link} spy={true} smooth={true} offset={-80} duration={500}>
        <Button className={classes.item}>{text[language].homepage}</Button>
      </Link>

      <Link to="service" className={classes.link} spy={true} smooth={true} offset={-80} duration={500}>
        <Button className={classes.item}>{text[language].service}</Button>
      </Link>

      <Link to="contact" className={classes.link} spy={true} smooth={true} offset={-80} duration={500}>
        <Button className={classes.item}>{text[language].contact}</Button>
      </Link>

      <Link to="project" className={classes.link} spy={true} smooth={true} offset={-80} duration={500}>
        <Button className={classes.item}>{text[language].completedProject}</Button>
      </Link>

      <MoveLink to={currentPath} className={classes.link}>
        <IconButton className={classes.itemLanguage} size="small">
          <Img className={classes.imgIcon} fluid={data.image_vn.childImageSharp.fluid} />
        </IconButton>
      </MoveLink>

      <MoveLink to={`/en${currentPath}`} className={classes.link}>
        <IconButton className={classes.itemLanguage} size="small">
          <Img className={classes.imgIcon}  fluid={data.image_en.childImageSharp.fluid} />
        </IconButton>
      </MoveLink>
    </div>
  );
}

Menu.defaultProps = {
  language: 'vi',
  currentPath: '',
}

Menu.propTypes = {
  language: PropTypes.string,
  currentPath: PropTypes.string,
}
