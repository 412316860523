import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Typography variant='caption'>
        © 2020 Khắc Phục Rò Rỉ. All Rights Reserved.
      </Typography>
    </div>
  );
}
